.DeleteCBModal {
  --deleteCBModal-content-fg: var(--color_white-80);
  --deleteCBModal-content-fontSize: 1rem;
  --deleteCBModal-title-fontSize: 1.25rem;
  --deleteCBModal-title-marginBottom: 1rem;

  @mixin tvDevice {
    --deleteCBModal-content-fg: var(--color_white-50);
    --deleteCBModal-content-fontSize: 1.875rem;
    --deleteCBModal-title-fontSize: 3rem;
    --deleteCBModal-title-marginBottom: 2rem;
    height: 100vh;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1.2rem;
    font-size: var(--deleteCBModal-content-fontSize);
    color: var(--deleteCBModal-content-fg);
    text-align: center;
  }

  &__title {
    margin: 1rem 0.625rem var(--deleteCBModal-title-marginBottom);
    font-family: var(--font_canal);
    font-size: var(--deleteCBModal-title-fontSize);
    color: var(--color_white);
    text-transform: uppercase;
  }

  &__label {
    margin-bottom: 1rem;
  }

  &__subLabel {
    margin-bottom: 2rem;
  }

  &__confirmBtn {
    margin-bottom: 1rem;
  }
}
