.CodeInputQrCodeTV {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0 0 1.25rem;
    font-size: 2.9375rem;
  }

  &__text {
    margin: 0 0 3.75rem;
    font-size: 1.9375rem;
    color: var(--color_white-50);
    text-align: center;
  }

  &__link {
    color: var(--color_white);
  }

  &__icon {
    background-color: var(--color_white);
    border-radius: var(--border-radius-extraLarge);
  }

  &__modal {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 68.75rem;
    height: 100vh;
    margin: auto;
  }
}
