.SeasonsTabsContainer {
  @mixin tvDevice {
    /* Add spacing to accommodate element scaling on focus, preventing visual cropping */
    --HZL-list-side-padding: 0.5rem;
  }
}

/**
 * Workaround: Prevent the first focused item in SeasonSelector from being clipped on TV devices.
 * Compensates for the padding value (--HZL-list-side-padding) with a negative margin.
 */
.SeasonsTabsContainer__seasonsList__tvBinder {
  @mixin tvDevice {
    margin-left: calc(-1 * var(--HZL-list-side-padding));
  }
}

.SeasonsTabsContainer__seasonsList {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  @mixin tvDevice {
    flex-wrap: nowrap;
  }

  h1 {
    @mixin resetH1;
  }

  abbr[title] {
    text-decoration: inherit;
  }
}
